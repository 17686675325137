import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/view/card'
import second from '@/view/card/second.vue'
import third from '@/view/card/thrid.vue'
import four from '@/view/card/four.vue'
import five from '@/view/card/five.vue'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: '/',
            component: index
        },
      {
        path: '/second',
        component: second
      },
      {
        path: '/third',
        component: third
      },
        {
            path: '/four',
            component: four
        },
        {
            path: '/five',
            component: five
        },
        {
            path: '/external-link',
            beforeEnter() { // 添加钩子函数来处理外部链接
                window.location = 'https://www.sheanow.com';
            }
        }
    ]
})

export default router
