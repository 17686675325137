<template>
  <div id="app">
    <div class="vcard" style=" background-color: #4AC1EC">


      <!-- 企业资质 -->
      <!--      <div class="bg_img">-->
      <!--        <div style="font-size: 1.5rem;padding-top: 20px;letter-spacing:0.5em;padding-left: 30px">科达斯特恩</div>-->
      <!--        <div style="padding-top: 4%;padding-left: 30px;font-size: 0.9rem">江苏科达斯特恩汽车科技股份有限公司</div>-->
      <!--        <div style="padding-top: 0px;padding-left: 30px;font-size: 0.4rem">llnngsu Koeds 5ione Awomative Teck ealony-->
      <!--          co..lid.-->
      <!--        </div>-->

      <!--        <div style="padding-top: 4%;padding-left: 30px;font-size: 1rem">叶文鹏 <span style="font-size: 0.75rem">深圳研发中心执行董事</span>-->
      <!--        </div>-->
      <!--        <div style="padding-top: 3%;padding-left: 14%;font-size: 0.6rem;padding-bottom: 15px">-->
      <!--          <div>电话: 159 8673 2722</div>-->
      <!--          <div>邮箱: xsb03@kd-stn.com</div>-->
      <!--          <div>地址: 江苏省常州市忡铁经济开发区枫林路39号</div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div style="width: 95%;margin: 10px auto">
        <img src="../images/1.jpg" style="width: 100%;height: auto">
      </div>
      <div style="width: 100%;margin: 0px auto">
        <img src="../images/new2.jpg" style="width: 100%;height: auto;margin-top: 40px">
        <div
            style="margin-top: 10px; display: flex;flex-direction: column;align-items: center;justify-content: center;background-color: #4AC1EC">
          <div class="centered-text">
            荣誉证书
          </div>
          <div style="font-size: 12px;color: #ffffff;padding-bottom: 10px">Certificate Honor</div>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around">
          <img style="width: 120px;height: 100px" src="../images/11.png">
          <img style="width: 120px;height: 90px" src="../images/12.png">
          <img style="width: 120px;height: 90px" src="../images/13.png">

        </div>
        <div
            style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;margin-top: 15px">
          <img style="width: 120px;height: 90px" src="../images/14.png">
          <img style="width: 120px;height: 90px" src="../images/15.png">
          <img style="width: 120px;height: 90px" src="../images/16.png">

        </div>
        <div
            style="margin-top: 30px; display: flex;flex-direction: column;align-items: center;justify-content: center;background-color: #4AC1EC">
          <div class="centered-text">
            发明专利
          </div>
          <div style="font-size: 12px;color: #ffffff;padding-bottom: 10px">Invention Patent</div>
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around">
          <img src="../images/z1.png" style="width: 25%;height: auto;margin-top: 0px">
          <img src="../images/z2.png" style="width: 25%;height: auto;margin-top: 0px">
          <img src="../images/z3.png" style="width: 25%;height: auto;margin-top: 0px">
          <img src="../images/z4.png" style="width: 25%;height: auto;margin-top: 0px">
        </div>
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around">
          <img src="../images/z5.png" style="width: 25%;height: auto;margin-top: 0px">
          <img src="../images/z6.png" style="width: 25%;height: auto;margin-top: 0px">
          <img src="../images/z7.png" style="width: 25%;height: auto;margin-top: 0px">
        </div>
        <div
            style="margin-top: 30px; display: flex;flex-direction: column;align-items: center;justify-content: center;background-color: #4AC1EC">
          <div class="centered-text">
            全国生产基地布局
          </div>
          <div style="font-size: 12px;color: #ffffff;padding-bottom: 10px">Interor Unit Location</div>

          <img src="../images/map.jpg" style="width: 80%;height: auto;margin-top: 0px">

          <div style="background-color: #0ABDEF;padding: 3px;font-size: 1rem">
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto">
              <div style="width: 50%;text-align: center">业务</div>
              <div style="width: 50%;text-align: center">数量</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;border-bottom: 1px solid #ffffff">
              <div style="width: 50%;text-align: center">Business</div>
              <div style="width: 50%;text-align: center">Quantity</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">常州Changzhou</div>
              <div style="width: 50%;text-align: center">3</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">湘潭Xiangtan</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">芜湖Wuhu</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">济南Jinan</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">郑州Zhengzhou</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">安庆Anqing</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">合肥Hefei</div>
              <div style="width: 50%;text-align: center">1</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">宁德Ningde</div>
              <div style="width: 50%;text-align: center">1(拟建)</div>
            </div>
            <div
                style="display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;">
              <div style="width: 50%;text-align: center">泰国Taiguo</div>
              <div style="width: 50%;text-align: center">1(拟建)</div>
            </div>
            <div
                style="padding-bottom: 10px;padding-top: 10px; display: flex;flex-direction: row;align-items: center;align-content: center;width: 95vw;margin: 0 auto;border-top: 1px solid #ffffff">
              <div style="width: 50%;text-align: center">总计Totally</div>
              <div style="width: 50%;text-align: center">11</div>
            </div>
          </div>
        </div>
        <img src="../images/new22.jpg" style="width: 100%;height: auto;margin-top: 20px">
        <!--        <img src="../images/z8.png" style="width: 100%;height: auto;margin-top: 0px">-->
      </div>

    </div>
    <footer>
      <div class="button-box">
        <button class="mar">
          <a href="tel:15986732722" class="green">拨打电话</a>
        </button>
        <!--                <button @click="showImage = true">关注微信公众号</button>-->
      </div>
    </footer>
    <div id="overlay" v-if="showImage" @click="closeImage">
      <div class="image-wrapper" @click.stop>
        <img id="img" src="../images/ewm.jpg" alt="图片"/>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

const jweixin = require('jweixin-module')
import sha1 from 'sha1'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "second",
  data() {
    return {
      showImage: false,
    };
  },
  mounted() {
    this.goShare();

  },
  methods: {
    closeImage() {
      this.showImage = false;
    },
    goShare() {
      const linkUrl = window.location.href.split('#')[0];
      let _this = this;

      axios({
        url: 'http://124.70.186.111:8041/api/wxTest/getS',
        method: 'get',
        headers: {
          "Access-Control-Allow-Origin": "*"
        },
        params: {
          appid: 'wx3a315ce79befc1a1'
        }
      }).then(res => {
        if (res.data) {

          let ticket = res.data.data;
          const timestamp = Math.round(new Date().getTime() / 1000).toString()
          //随机字符串
          const nonceStr = _this.randomString()

          const jsons =
              `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${linkUrl}`

          // 把签名转为sha算法
          const signature = sha1(jsons);

          jweixin.config({
            debug: false, // 调试模式
            appId: 'wx3a315ce79befc1a1', // 公众平台申请的id
            timestamp, // 时间戳
            nonceStr, // 随机字符串
            signature, // 签名
            jsApiList: [ // 申请需要使用的api
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ]
          })

          jweixin.ready(() => {
            const shareData = {
              title: '叶文鹏 ', // 标题
              desc: ' 深圳研发中心执行董事\n江苏科达斯特恩汽车科技股份有限公司\nllnngsu Koeds 5ione Awomative Teck ealony co..lid.', //详情
              link: "http://mingpian.sheanow.com/#/five", // h5链接
              imgUrl: 'http://mingpian.sheanow.com/static/img/logo.jpg' // 图片链接 只能是网络连接
            }
            //分享给朋友
            jweixin.updateAppMessageShareData(shareData)
            //分享到朋友圈
            jweixin.updateTimelineShareData(shareData)
          })
        }
      })


    },
    randomString() {
      let len = 16
      const str = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = str.length;
      let value = ''
      for (let i = 0; i < len; i++) {
        value += str.charAt(Math.floor(Math.random() * maxPos))
      }
      return value;

    },


  },
};
</script>
<style lang="scss" scoped>

.centered-text {
  display: inline-block;
  position: relative;
  padding: 3px 10px;
  color: white;
  background-color: #D56E24;
  font-weight: 600;
  margin: 20px 10px 0 10px;
  font-size: 20px;
  border-radius: 10px;
}

.centered-text::before,
.centered-text::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100vw; /* 两侧横线宽度拉至屏幕宽度 */
  height: 1px;
  background-color: #D56E24;
}

.centered-text::before {
  left: -100vw;
  border-top: 1px solid #D56E24;
  margin-right: 10px;
}

.centered-text::after {
  right: -100vw;
  border-top: 1px solid #D56E24;
  margin-left: 10px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#img {
  max-width: 60%;
  max-height: 60%;
}

body {
  background-color: #4AC1EC;
}

.vcard {
  width: 100%;
  overflow: hidden;
  background-color: #4AC1EC;
  margin-top: 00px;
  padding-top: 10px;
  margin-bottom: 50px;
  position: relative;
  opacity: 0.96;

  .bg_img {
    width: 95%;
    margin: 10px auto;
    background-image: url("../images/bag.jpg");
    background-size: 100% 100%;
    //height: 350px;
    font-weight: 450;
  }

  p {
    margin-bottom: 5px;
  }

  .media {
    width: 100%;
    padding: 10px 5px;
    color: #666;
    background-color: white;

    .media-left {
      vertical-align: middle;
      padding-right: 10px;

      img {
        height: 100px;
        width: 100px;
        border-radius: 50%;
      }
    }

    .media-body {
      padding-left: 15px;
      padding-top: 10px;

      h3 {
        color: #000;
        margin-bottom: 5px;
      }

      .corp-name {
        color: #333;
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }

  .info {
    span {
      font-size: 12px;
    }

    .list-group {
      padding-left: 0;
      margin-bottom: 20px;

      .list-group-item {
        border-radius: 0 !important;
        background-color: #fcf7f7;
        position: relative;
        display: block;
        padding: 10px 15px;
        margin-bottom: -1px;
        border: 1px solid #ddd;

        .glyphicon {
          padding-right: 15px;
          color: #52c9b3;
        }

        a:focus,
        a:hover {
          color: #23527c;
          text-decoration: underline;
        }

        a:active,
        a:hover {
          outline: 0;
        }

        .green {
          // color: #52c9b3;
          color: #666;
        }
      }
    }
  }

  .flag-title {
    color: white;
    background-color: #52c9b3;
    height: 80px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
  }

  // 公司简介
  .intro {
    width: 100%;
    padding: 10px;
    font-weight: 400;
    color: #888;

    .box {
      width: 100%;
      height: 160px;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .box-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .laboratory {
      width: 100%;
      height: 200px;
    }
  }

  // 企业资质
  .qualification {
    margin-top: 10px;

    .imgbox {
      display: flex;
      justify-content: space-around;

      img {
        width: 100%;
        height: 500px;
        margin-bottom: 5px;
      }
    }
  }

  // 合作伙伴
  .customer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 5px;
    margin-bottom: 45px;

    img {
      width: 18%;
      height: 300px;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}

footer {
  height: 50px;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 5px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eee;
  background-color: #efefef;
  z-index: 999;

  .button-box {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-around;

    button {
      width: 50%;
      height: 40px;
      color: #fff;
      background-color: #0A6199;
      border: none;
      font-size: 16px;
      border-radius: 5px;
    }

    .mar {
      margin-right: 10px;

      a:hover,
      a:visited,
      a:link,
      a:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}
</style>
