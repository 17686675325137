<template>
    <div id="app">
        <div class="vcard">
            <!-- 个人介绍 -->
            <div class="media">
                <div class="media-left">
                    <img src="../images/photo4.png" alt=""/>
                </div>
                <div class="media-body">
                    <h3>梁旭 </h3>
                    <p>董事总经理</p>
                    <p class="corp-name">中科醒诺集团 Zhongke Sheanow Group</p>
                    <p class="corp-name">醒诺中科技术产业（深圳）有限公司</p>
                </div>
            </div>
            <div class="info">
                <ul class="list-group">
                    <li class="list-group-item">
                        <span class="glyphicon">·</span>
                        <a href="tel:13153857777" class="green">电话：13153857777</a>
                    </li>
                    <li class="list-group-item">
                        <span class="glyphicon">·</span>
                        <a class="green">邮箱：704349895@qq.com</a>
                    </li>
                    <li class="list-group-item">
                        <span class="glyphicon">·</span>
                        <a href="http://www.sheanow.com" target="_blank" class="green" >网址：www.sheanow.com




                        </a>
                    </li>
                    <li class="list-group-item">

                        <a class="green"
                        >

                            <a  class="green"  >
                                <div style="display: flex;flex-direction: row">
                                    <span class="glyphicon">·</span>
                                    <div style="white-space:nowrap">
                                        地址：
                                    </div>
                                    <div>深圳市南山区南头街道马家龙一街68栋2号门首层</div>
                                </div>






                            </a>


                            <br/>
                            <span>
<!--                222 Lvyin Road, Qiantang District, Hangzhou City，Zhejiang-->
                                <!--                Province-->
              </span>
                        </a>
                    </li>
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >中科前沿技术成果转化委员会秘书长 <br/>-->
                    <!--                            <span-->
                    <!--                            >Secretary General of Expert Committee on the Transformation of-->
                    <!--                Frontier Technological</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >德国约克夏集团中国环保公司董事 <br/>-->
                    <!--                            <span>-->
                    <!--                Director of China Environmental Protection Company of Germany-->
                    <!--                Yorkshire Group</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >戴姆勒梅赛德斯奔驰汽车公司摆渡车空调净化系统研发工程师 <br/>-->
                    <!--                            <span>-->
                    <!--                R&D Engineer of Air Conditioning and Purification System for-->
                    <!--                Ferry Car of Daimler Mercedes Benz Automotive Company</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >大健康数字科创发展中心常务理事长 <br/>-->
                    <!--                            <span>-->
                    <!--                Executive Director of Digital Health Innovation and Development-->
                    <!--                Center</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >中国主题公园研究院室内空气管理分院副院长 <br/>-->
                    <!--                            <span>-->
                    <!--                Vice President of Indoor Air Management Branch of China Theme-->
                    <!--                Park Research Institute</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >物联O20模式早期架构师 <br/><span-->
                    <!--                        >Early architect of IoT O2O mode</span-->
                    <!--                        >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >获得国家专利二十余项 <br/>-->
                    <!--                            <span> Obtained over 20 national patents</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li class="list-group-item">-->
                    <!--                        <span class="glyphicon">·</span>-->
                    <!--                        <a class="green"-->
                    <!--                        >重要研究领域：空气精密智控系统搭建 <br/>-->
                    <!--                            <span-->
                    <!--                            >Main research field: construction of air precision intelligent-->
                    <!--                control system</span-->
                    <!--                            >-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                </ul>
            </div>
            <!-- 公司介绍 -->
            <div class="flag-title">
                <h4>公司简介</h4>
                <p>Company Profile</p>
            </div>
            <div class="intro">
                <p style="text-indent: 2em">
                    中科醒诺是一家中科旗下优秀的产业公司，依托强有力的院士团队技术，在对城市空气的精密智控、监测预警、防控救治等方面问题提出了城市防火墙概念：使用空间感知、空气精密智控、高能粒子轰击隧道、数字孪生、量子力学、空气动力学、光电感知、大数据、云计算、人工智能等前沿技术，对城市公共卫生管理平台进行改造升级，让城市管理者可以精准查询全市的空气指标、落菌率、实时细菌分析、监控报警状况，对空气的趋势进行准确判断，并提升应急资源的跟踪和调配能力的高科技公司。
                </p>
                <div class="box">
                    <div class="box-item">
                        <img src="../images/1.png"/>
                        <span>应用检测<br/>实验室2个</span>
                    </div>
                    <div class="box-item">
                        <img src="../images/2.png"/>
                        <span>中科共享<br/>实验室6个</span>
                    </div>
                    <div class="box-item">
                        <img src="../images/3.png"/>
                        <span
                        >研究中心<br/>
              1 个</span
                        >
                    </div>
                    <div class="box-item">
                        <img src="../images/4.png"/>
                        <span
                        >联合实验室<br/>
              2 个
            </span>
                    </div>
                </div>
                <!--                <img class="laboratory" src="../images/laboratory.png"/>-->
                <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around">
                    <img style="width: 120px;height: 100px" src="../images/11.png">
                    <img style="width: 120px;height: 90px" src="../images/12.png">
                    <img style="width: 120px;height: 90px" src="../images/13.png">

                </div>
                <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;margin-top: 15px">
                    <img style="width: 120px;height: 90px" src="../images/14.png">
                    <img style="width: 120px;height: 90px" src="../images/15.png">
                    <img style="width: 120px;height: 90px" src="../images/16.png">

                </div>
            </div>



            <!-- 企业资质 -->
            <div class="flag-title">
                <h4>企业资质</h4>
                <p>Enterprise qualification</p>
            </div>
            <img src="../images/133.png" style="width: 100%;margin-top: 20px;height: auto">
            <img src="../images/134.png" style="width: 100%;margin-top: 20px;height: auto">
            <!--            <div class="qualification">-->
            <!--                <div style="padding: 15px 15px;font-size: 1.2rem;font-weight: bold">技术转换</div>-->
            <!--                <div style="padding:0  15px;background-color: #ffffff;;font-size: 0.9rem">-->
            <!--                    <div style="background-color: #52c9b3;color: #ffffff;;font-size:1.1rem; display: flex;flex-direction: row;align-items: center;">-->
            <!--                        <div style="padding-left: 15px;width: 50%">荣誉奖项</div>-->
            <!--                        <div>颁发单位</div>-->
            <!--                    </div>-->

            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 40px;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 45%;border-right:1px solid #52c9b3;height: 40px;line-height: 40px">-->
            <!--                            高新技术企业-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%">浙江省科学技术厅</div>-->
            <!--                    </div>-->
            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 40px;;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 45%;border-right:1px solid #52c9b3;height: 40px;line-height: 40px">-->
            <!--                            优秀科技成果转化企业-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%">中科前沿技术转化专家委员会</div>-->
            <!--                    </div>-->
            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 60px;;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 45%;border-right:1px solid #52c9b3;height: 60px;display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap">-->
            <!--                            浙江省优秀科技型中小企业-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%">浙江省科学技术厅</div>-->
            <!--                    </div>-->
            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 40px;;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 45%;border-right:1px solid #52c9b3;height: 40px;line-height: 40px">-->
            <!--                            中科院技木战略合作伙伴-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%">中国科学院</div>-->
            <!--                    </div>-->
            <!--                </div>-->


            <!--                <div style="padding: 15px 15px;font-size: 1.2rem;font-weight: bold;margin-top: 30px">发明创新</div>-->
            <!--                <div style="padding:0  18px;margin-bottom: 30px;;font-size: 0.9rem">-->
            <!--                    <div style="background-color: #52c9b3;color: #ffffff; ;font-size: 1.1rem;display: flex;flex-direction: row;align-items: center;">-->
            <!--                        <div style="padding-left: 15px;width: 50%">专利</div>-->
            <!--                        <div>名称</div>-->
            <!--                    </div>-->

            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 300px;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 35%;border-right:1px solid #52c9b3;height: 300px;line-height: 300px">-->
            <!--                            实用新型专利-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%;padding-top: 10px;width: 65%;font-size: 0.9rem">-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                一种高压消毒单元、結构及装置-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                一种空调进风口复合过滤装置-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                一种风口控制监測电路-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2">-->
            <!--                                带有火灾除烟功能的空气净化设备-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2">-->
            <!--                                模块化静电除尘器-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2">-->
            <!--                                一种高压微米圾磨砂壁式空气除尘装置-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2">-->
            <!--                                一种基于交变电场和空气对流除自动集尘装置-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 100px;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 35%;border-right:1px solid #52c9b3;height: 100px;line-height: 100px">-->
            <!--                            发明专利-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%;padding-top: 10px;width: 65%;font-size: 0.9rem">-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                一种甲醛清除剂、清除装置及其使用方法-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                一种空气净化材料及制备方法-->
            <!--                            </div>-->

            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div style="display: flex;flex-direction: row;align-items: center;height: 130px;font-size: 0.9rem;border-bottom: 1px solid #52c9b3">-->
            <!--                        <div style="padding-left: 15px;width: 35%;border-right:1px solid #52c9b3;height: 130px;line-height: 130px">-->
            <!--                            软件著作-->
            <!--                        </div>-->
            <!--                        <div style="padding-left: 5%;padding-top: 10px;width: 65%;font-size: 0.9rem">-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                电路板自主控制系统-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                空气净化消毒机用智能控制系统-->
            <!--                            </div>-->
            <!--                            <div style="line-height: 2;font-size: 0.9rem">-->
            <!--                                智能识别模式自动切換系统-->
            <!--                            </div>-->

            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--                &lt;!&ndash;                <div >&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-around">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 150px" src="../images/s1.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 150px" src="../images/s2.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 150px" src="../images/s3.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 150px" src="../images/s4.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 640px" src="../images/s6.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                        <img style="width: 150px" src="../images/s5.png">&ndash;&gt;-->
            <!--                &lt;!&ndash;                    </div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </div>&ndash;&gt;-->

            <!--            </div>-->
            <!-- 合作伙伴 -->
            <div class="flag-title">
                <h4>合作伙伴</h4>
                <p>Customer case</p>
            </div>
            <div class="customer">
                <!--                <img src="../images/31.png"/>-->
                <!--                <img src="../images/32.png"/>-->
                <!--                <img src="../images/33.png"/>-->
                <!--                <img src="../images/34.png"/>-->
                <!--                <img src="../images/35.png"/>-->

            </div>
            <img style="width: 100%;height: 350px" src="../images/132.png"/>
            <div class="customer">

            </div>
        </div>
        <footer>
            <div class="button-box">
                <button class="mar">
                    <a href="tel:13153857777" class="green">拨打电话</a>
                </button>
                <button @click="showImage = true">关注微信公众号</button>
            </div>
        </footer>
        <div id="overlay" v-if="showImage" @click="closeImage">
            <div class="image-wrapper" @click.stop>
                <img id="img" src="../images/ewm.jpg" alt="图片"/>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
const jweixin = require('jweixin-module')
import sha1 from 'sha1'
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "second",
    data() {
        return {
            showImage: false,
        };
    },
    mounted() {
        // this.goShare();

    },
    methods: {
        closeImage() {
            this.showImage = false;
        },
        goShare() {
            const linkUrl = window.location.href.split('#')[0];
            let _this = this;

            axios({
                url:'http://124.70.186.111:8041/api/wxTest/getS',
                method:'get',
                headers:{
                    "Access-Control-Allow-Origin":"*"
                },
                params:{
                    appid: 'wx3a315ce79befc1a1'
                }
            }).then(res=>{
                if(res.data){

                    let ticket = res.data.data;
                    const timestamp = Math.round(new Date().getTime() / 1000).toString()
                    //随机字符串
                    const nonceStr = _this.randomString()

                    const jsons =
                        `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${linkUrl}`

                    // 把签名转为sha算法
                    const signature = sha1(jsons);

                    jweixin.config({
                        debug: false, // 调试模式
                        appId: 'wx3a315ce79befc1a1', // 公众平台申请的id
                        timestamp, // 时间戳
                        nonceStr, // 随机字符串
                        signature, // 签名
                        jsApiList: [ // 申请需要使用的api
                            'updateAppMessageShareData',
                            'updateTimelineShareData'
                        ]
                    })

                    jweixin.ready(() => {
                        const shareData = {
                            title: '梁旭 ', // 标题
                            desc: ' 董事总经理                          中科醒诺集团                       Zhongke Sheanow Group', //详情
                            link: "http://mingpian.sheanow.com/#/four", // h5链接
                            imgUrl: 'http://mingpian.sheanow.com/static/img/photo4.png' // 图片链接 只能是网络连接
                        }
                        //分享给朋友
                        jweixin.updateAppMessageShareData(shareData)
                        //分享到朋友圈
                        jweixin.updateTimelineShareData(shareData)
                    })
                }
            })




        },
        randomString() {
            let len = 16
            const str = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
            const maxPos = str.length;
            let value = ''
            for (let i = 0; i < len; i++) {
                value += str.charAt(Math.floor(Math.random() * maxPos))
            }
            return value;

        },


    },
};
</script>
<style lang="scss" scoped>
#overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#img {
    max-width: 60%;
    max-height: 60%;
}

.vcard {
    width: 100%;
    overflow: hidden;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 80px;
    position: relative;
    opacity: 0.96;

    p {
        margin-bottom: 5px;
    }

    .media {
        width: 100%;
        padding: 10px 5px;
        color: #666;
        background-color: white;

        .media-left {
            vertical-align: middle;
            padding-right: 10px;

            img {
                height: 100px;
                width: 100px;
                border-radius: 50%;
            }
        }

        .media-body {
            padding-left: 15px;
            padding-top: 10px;

            h3 {
                color: #000;
                margin-bottom: 5px;
            }

            .corp-name {
                color: #333;
                font-size: 0.8rem;
                font-weight: 500;
            }
        }
    }

    .info {
        span {
            font-size: 12px;
        }

        .list-group {
            padding-left: 0;
            margin-bottom: 20px;

            .list-group-item {
                border-radius: 0 !important;
                background-color: #fcf7f7;
                position: relative;
                display: block;
                padding: 10px 15px;
                margin-bottom: -1px;
                border: 1px solid #ddd;

                .glyphicon {
                    padding-right: 15px;
                    color: #52c9b3;
                }

                a:focus,
                a:hover {
                    color: #23527c;
                    text-decoration: underline;
                }

                a:active,
                a:hover {
                    outline: 0;
                }

                .green {
                    // color: #52c9b3;
                    color: #666;
                }
            }
        }
    }

    .flag-title {
        color: white;
        background-color: #52c9b3;
        height: 80px;
        padding-top: 20px;
        width: 100%;
        text-align: center;
    }

    // 公司简介
    .intro {
        width: 100%;
        padding: 10px;
        font-weight: 400;
        color: #888;

        .box {
            width: 100%;
            height: 160px;
            padding: 10px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .box-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 10px;
                }

                span {
                    font-size: 14px;
                }
            }
        }

        .laboratory {
            width: 100%;
            height: 200px;
        }
    }

    // 企业资质
    .qualification {
        margin-top: 10px;

        .imgbox {
            display: flex;
            justify-content: space-around;

            img {
                width: 100%;
                height: 500px;
                margin-bottom: 5px;
            }
        }
    }

    // 合作伙伴
    .customer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 5px;
        margin-bottom: 45px;

        img {
            width: 18%;
            height: 300px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
}

footer {
    height: 50px;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 5px;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #eee;
    background-color: #efefef;
    z-index: 999;

    .button-box {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        display: flex;
        justify-content: space-around;

        button {
            width: 50%;
            height: 40px;
            color: #fff;
            background-color: #52c9b3;
            border: none;
            font-size: 16px;
            border-radius: 5px;
        }

        .mar {
            margin-right: 10px;

            a:hover,
            a:visited,
            a:link,
            a:active {
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: top;
}
</style>
